import React from 'react'
import DefaultLayout from '../layouts/defaultLayout'
import Content from '../components/Thankyou/Content'

export default function Thankyou() {
  return (
    <DefaultLayout>
      <Content />
    </DefaultLayout>
  )
}
