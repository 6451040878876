import React, { useState } from 'react'
import Cookies from 'universal-cookie';

const Content = () => {
  let cookies = new Cookies();
  let s_id = cookies.get('s_id');
  let hash = cookies.get('orderHash');

  return (
    <div className="container mx-auto py-6">
      <h1 className="text-3xl text-primary mb-4">Vielen Dank für Deine Bestellung!</h1>
      <div className="mb-4">
        <p className="mb-4">Sehr geehrter Kunde,</p>
        <p className="mb-4">
          Deine Bestellung wurde erfolgreich an uns übermittelt.
          <br />
          Wir bereiten alles weitere für Dich vor, sodass Dein bestellter Artikel zeitnah
          ausgeliefert werden kann.
        </p>
        <p className="mb-4">
          Solltst Du Fragen haben kannst Du uns auch eine Mail an{' '}
          <a href="mailto:vodafone@jcc.group">vodafone@jcc.group</a> senden.
        </p>
        {s_id !== undefined &&
        <img
            src={`https://cts.communicationads.net/tl.php?p=286&oid=${hash}&ocategory=default&ovalue=0.00&s_id=${s_id}`}
            alt=""
            border="0"
            width="0"
            height="0" />}
      </div>
    </div>
  )
}

export default Content
